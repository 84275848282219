.c-hero {
  min-height: 100vh;
  text-align: left;
  background: linear-gradient(to bottom, #ead799, #fff);
  background-repeat: no-repeat;
  background-position: center center;
  color: #a800a8;
  background-color: #ead799;
  padding: 4rem 0 5rem;
  display: flex;
  align-items: center;

  @media (min-width: $md) {
    padding: 10rem 0;
  }
}

.c-hero__heading {
  color: #000080;

  @media (min-width: $lg) {
    font-size: 6rem;
  }
}

.c-hero__link {
  color: $accent;
  margin-top: var(--vr);

  &:hover,
  &:focus {
    color: $lightGrey;
  }
}
