body {
  font-family: 'Lato', sans-serif;

  @media (min-width: $md) {
    font-size: 1.2rem;
  }
}

h1,
h2,
h3,
h4 {
  line-height: 1.1;
  font-family: 'Amiri', serif;
  font-weight: 700;
  margin: 0;
}

h2,
h3 {
  letter-spacing: -0.02em;
}

h1 {
  line-height: 1.1;
  letter-spacing: -0.03em;

  @media (min-width: $md) {
    font-size: 4.5rem;
  }
}

h2 {
  line-height: 1.2;

  @media (min-width: $md) {
    font-size: 2.8rem;
  }
}

h3 {
  font-family: 'Lato', sans-serif;
  font-size: 1.1rem;

  @media (min-width: $md) {
    font-size: 1.5rem;
  }
}

p {
  line-height: 1.5;
}

a {
  color: lighten($bgDark, 20%);

  &:hover,
  &:focus {
    color: $bgDark;
  }
}
