body {
  background-color: $white;
  color: $bgDark;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  font-display: swap;
}

.container {
  max-width: 60rem;
  margin: 0 auto;
  padding: 0 1rem;

  @media (min-width: $md) {
    padding: 0 2rem;
  }
}

.page-content {
  padding: 6rem 0 2rem 0;
  flex-grow: 1;

  @media (min-width: $md) {
    padding: 10rem 0;
  }
}
