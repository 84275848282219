.c-header {
  padding: 1rem 1rem 0.5rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  @media (min-width: $md) {
    padding-right: 1rem;
    min-height: 4.5rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 2rem;
  }
}

.c-header--bg {
  background-color: #000080;
}

.c-header__menu-btn {
  position: absolute;
  top: 0;
  right: 0;
  appearance: none;
  border: 2px solid transparent;
  background-color: transparent;
  color: #000080;
  transition: color 200ms;
  text-decoration: underline;
  font-family: inherit;
  margin-left: auto;
  padding: 1rem;
  font-size: 1rem;
  z-index: 2;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #000080;
    border: 2px solid #a800a8;
  }

  @media (min-width: $md) {
    right: 1.5rem;
    top: 0.5rem;
    font-size: 1.2rem;
  }

  &[aria-expanded='true'] {
    background-color: $white;
  }
}

.c-header__nav {
  margin-left: auto;
}

.c-header__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;

  @media (min-width: $lg) {
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
}

.c-header__link {
  padding: 0.5rem 1rem;
  color: $lightGrey;
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;

  svg {
    margin-left: 0.5rem;
    width: 1.75rem;
    height: 1.75rem;
  }

  &:hover,
  &:focus {
    color: $white;
    text-decoration: underline;
  }

  @media (min-width: $md) {
    font-size: 1rem;
  }

  @media (min-width: $lg) {
    font-size: 1.1rem;
  }
}

.c-header__title {
  font-family: 'Amiri', serif;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 0.5rem;

  a {
    display: block;
    color: $accent;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  @media (min-width: $md) {
    font-size: 2rem;
    margin-bottom: 0;
    padding: 0.5rem 0;
  }
}

.js-menu {
  .c-header__nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 40rem;
    height: 100vh;
    padding: 5rem 1rem 2rem 1rem;
    overflow-y: scroll;
    background-color: darken($bgDark, 5%);
    transform: translate3d(100%, 0, 0);
    transition: transform 250ms;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.25);

    @media (min-width: $md) {
      padding: 5rem 2rem 2rem 2rem;

      .c-header__link {
        font-size: 1.2rem;
      }
    }
  }

  .c-header__list {
    display: block;
    margin-left: 0;
  }
}

.is-visible {
  .c-header__nav {
    transform: translate3d(0, 0, 0);
  }
}
